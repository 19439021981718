body {
  margin: 1em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.25;
}

code, pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

dt {
  font-weight: bold;
  margin: 0.5em 0 0.25em;
}

dt::after {
  content: ":";
}

dd {
  margin: 0.25em 0 0.5em;
}

h1, h2 {
  margin: 1em 0;
}